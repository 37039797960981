import { render, staticRenderFns } from "./manage.vue?vue&type=template&id=416cdc40&scoped=true&"
import script from "./manage.vue?vue&type=script&lang=js&"
export * from "./manage.vue?vue&type=script&lang=js&"
import style0 from "./manage.vue?vue&type=style&index=0&id=416cdc40&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "416cdc40",
  null
  
)

export default component.exports