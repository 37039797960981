<template>
  <div class="home_page">
    <topMenu></topMenu>
    <div class="logo_bg">
      <el-image :src="url">
        <div slot="placeholder">图片加载中</div>
      </el-image>
    </div>
    <div class="about_brief" @click="handleAnchor">
      <div class="brief_top">
        <h4>关于元年</h4>
        <span class="more">查看更多>></span>
      </div>
      <div style="font-size: 16px;line-height: 2;text-align: justify">
      上海元年金融信息服务有限公司成立于2015年，注册资本5000万元，是一家通过ISAE3402服务机构控制水平认证，并获得中国证券投资基金业协会份额登记与估值核算两项业务资格的独立三方基金服务机构。</div>
    </div>
    <!-- 元年介绍 -->
    <div class="padding110 introduce_bg">
      <div class="yn_introduce flex justify_content" id="introduce">
        <div class="left">
          <div class="yn_introduce_title">元年介绍</div>
          <div class="yn_introduce_content">
            <p>上海元年金融信息服务有限公司成立于2015年，是一家通过ISAE3402服务机构控制水平认证，并获得中国证券投资基金业协会份额登记（TA）、估值核算（FA）两项业务资格的独立三方基金服务机构（备案号A00044）。</p>
            <p style="padding-top: 12px;">多年来，元年金服专注于为国内外私募基金管理人提供基金行政管理服务，拥有专业的基金中后台运营系统和国内外基金服务团队，致力于为客户提供专业高效的基金运营方案。同时，持续拓展人资服务业务线，为多家知名银行、理财子、证券的运营部门提供人资外包服务。目前服务团队超百人，各类私募投资基金和资管产品服务规模累计超千亿元。</p>
            <p style="padding-top: 12px;">元年金服秉承“赋能资管专业运营，助力国人财富增长”的使命；实践服务、求实、创新、执行、信义的企业价值观；立志成为最受信赖的第三方资管运营服务机构；做好资管行业的专心管家。</p>
          </div>
        </div>
        <div class="left">
          <div class="yn_introduce_title">服务范围</div>
          <div class="yn_introduce_tag">
            <div class="item flex align_items"
            v-for="(item, index) in introduceList"
            :key="index"
            @mouseover="changeStyle(true)"
            @mouseout="changeStyle(false)"
            @click="introduceHandle(item.path)">
              <img :src="item.icon" alt="" class="icon"><span>{{item.title}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contact :dialog="contactStatus"></Contact>
    <!-- 加入我们、新闻资讯 -->
    <div class="padding110 introduce_bg">
      <!-- 加入我们 -->
      <div class="join_my" id="my">
        <div class="yn_introduce_title" style="padding-bottom: 0; padding-top: 50px;">加入我们</div>
        <div class="yn_join flex">
          <div class="join_item" 
          v-for="(item, index) in joinList" 
          :key="index" 
          @click="handRowClick(item)" 
          @mouseover="changeStyle(true)"
          @mouseout="changeStyle(false)">{{item.title}}</div>
        </div>
      </div>
      <!-- 新闻资讯 -->
      <div class="new" id="new">
        <div class="yn_introduce_title" style="padding-top: 60px;">新闻资讯</div>
        <el-carousel indicator-position="outside">
          <el-carousel-item v-for="(item, index) in newList" :key="index" interval="5000">
            <div class="slide">
              <div class="image-container" v-for="(sub, index1) in item.itemList" :key="index1" @click="handCompanyNewsClick(sub)">
                <img :src="sub.img" alt="" class="new_img">
                <p class="new_name">{{sub.name}}</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="footer" id="footer">
      <FooterBottom></FooterBottom>
    </div>
  </div>
</template>

<script>
import url from '../assets/home_page_bg.jpg';
import introduce_icon1 from '../assets/introduce_icon1.png';
import introduce_icon2 from '../assets/introduce_icon2.png';
import introduce_icon3 from '../assets/introduce_icon3.png';
import new_1 from '../assets/new_1.jpg';
import new_2 from '../assets/new_2.jpg';
import new_3 from '../assets/new_3.jpg';
import new_4 from '../assets/new_4.jpg';
import new_5 from '../assets/new_5.jpg';
import new_6 from '../assets/new_6.jpg';
import new_7 from '../assets/new_7.jpg';
import new_8 from '../assets/new_8.png';
import new_9 from '../assets/new_9.jpg';
import new_10 from '../assets/new_10.jpg';
import new_11 from '../assets/new_11.jpg';
import new_12 from '../assets/new_12.jpg';
import new_13 from '../assets/new_13.jpg';
import new_14 from '../assets/new_14.jpg';
import new_15 from '../assets/new_15.jpg';
import new_16 from '../assets/new_16.jpg';
import new_17 from '../assets/new_17.jpg';
import new_18 from '../assets/new_18.jpg';
import new_19 from '../assets/new_19.jpg';
import new_20 from '../assets/new_20.jpg';
import new_21 from '../assets/new_21.jpg';
import new_22 from '../assets/new_22.jpg';
import new_23 from '../assets/new_23.jpg';
import new_24 from '../assets/new_24.jpg';
import new_25 from '../assets/new_25.jpg';
import new_26 from '../assets/new_26.jpg';
import new_27 from '../assets/new_27.jpg';


import {positionList,associationNewsList,companyNewsList} from './constData'
import TopMenu from './menu'
import Contact from './contact'
import FooterBottom from './footer'

export default {
  name: 'HomePage',
  props: {

  },
  components: {TopMenu,Contact,FooterBottom},
  data() {
    return {
      url,
      screenHeight: 0,
      fontSize: 16,
      introduceList: [
        {
          icon: introduce_icon1,
          title: '人民币基金行政管理服务',
          path: '/services/RMB'
        },
        {
          icon: introduce_icon2,
          title: '美元基金行政管理服务',
          path: '/services/dollar'
        },
        {
          icon: introduce_icon3,
          title: '资管机构中后台运营全方位专业支持',
          path: '/services/manage'
        }
      ],
      joinList: [
        { id: 1, title: '基金会计 —— 上海/北京' },
        { id: 2, title: '美元基金会计 —— 上海' },
        { id: 3, title: '账户管理(项目驻场) —— 上海' },
        { id: 4, title: '基金会计(项目驻场) —— 上海' },
        { id: 5, title: '交易助理(项目驻场) —— 上海' },
        { id: 6, title: '项目助理(项目驻场) —— 上海' },
        { id: 7, title: '份额登记(项目驻场) —— 上海' },
        { id: 8, title: '估值核算(项目驻场) —— 北京' },
        { id: 9, title: '法律事务助理(项目驻场) —— 上海' },
        { id: 10, title: '数据管理服务助理-数据统计方向(项目驻场) - 上海' },
      ],
      newList: [
        {
          itemList: [
            {
              img: new_13,
              name: '重磅新规落地！银行代销“筛子”收紧，对私募管理人影响几何？',
              link: 'https://mp.weixin.qq.com/s/VxJ9-kknffqqcLd6-sMQig'
            },
            {
              img: new_20,
              name: '私募基金募集流程与合规要求全视角解析',
              link: 'https://mp.weixin.qq.com/s/23iTGta-NYw75D2kPPrC0w'
            },
            {
              img: new_20,
              name: '图示｜私募管理人/基金定期信息报送指南',
              link: 'https://mp.weixin.qq.com/s/TGcV-ghGZUoN9gRixqMy_Q'
            }
          ],
        },
        {
          itemList: [
            {
              img: new_4,
              name: '私募基金注册地选择：84%私募基金集中注册在这十个省份',
              link: 'https://mp.weixin.qq.com/s/wO5QYQ2IQFhApcQXrhcapQ'
            },
            {
              img: new_6,
              name: '私募运营合规问答！子公司与分公司的员工从业资格、结构化产品认定、合伙企业穿透、信披、份额转让等',
              link: 'https://mp.weixin.qq.com/s/YxrLUri0BqLaLjYuPG1iQw'
            },
            {
              img: new_18,
              name: '企业出海新时代—开曼豁免公司EC（Exempted Company）',
              link: 'https://mp.weixin.qq.com/s/sa7QRqcYC-JeQaUGWXqhug'
            }
          ],
        },
        {
          itemList: [
            {
              img: new_8,
              name: '2025年2月元年红宝书｜私募监管动态、违规处罚案例、合规待办提醒、私募监管新规等',
              link: 'https://mp.weixin.qq.com/s/oTqKrrnoOzQZRpo_R0cjMQ'
            },
            {
              img: new_5,
              name: '私募合规关注！附2月私募违规处罚案例',
              link: 'https://mp.weixin.qq.com/s/fcv2MZkQEEtb0N7YL51wQQ'
            },
            {
              img: new_20,
              name: '私募基金重大变更指南：要求、报送材料、办理程序一文速览',
              link: 'https://mp.weixin.qq.com/s/kXJcM4DgONUfpxucF23lTg'
            }
          ],
        },
        {
          itemList: [
            {
              img: new_11,
              name: '2月私募数据｜基金备案新增817只，管理人登记新增6家，注销新增24家',
              link: 'https://mp.weixin.qq.com/s/9iXFOpQGue_LB1PiuqIq0Q'
            },
            {
              img: new_20,
              name: '为什么基金需要基金行政管理人？',
              link: 'https://mp.weixin.qq.com/s/oKscnByIXT81hZk6uR7vrg'
            },
            {
              img: new_25,
              name: '2025年4月1日起施行！税务总局发文调整《中国税收居民身份证明》有关事项',
              link: 'https://mp.weixin.qq.com/s/CxMAZkvhFGMVKY5yzKtNxg'
            },
          ],
        },
        {
          itemList: [
            {
              img: new_23,
              name: '协会通知｜关于发布私募基金管理人主动注销登记业务流程“一图通”及配套说明的通知',
              link: 'https://mp.weixin.qq.com/s/vFT_dK0iHe3b6M5Vz8Nn4g?token=679679999&lang=zh_CN'
            },
            {
              img: new_15,
              name: '中基协发布：私募基金纪律处分典型案例',
              link: 'https://mp.weixin.qq.com/s/3CyvtaedBU6i65tIouJsQA?token=679679999&lang=zh_CN'
            },
            {
              img: new_15,
              name: '提醒：中基协发布私募机构排查通知，含分支机构信息报送和资格管理员信息确认',
              link: 'https://mp.weixin.qq.com/s/jyYa_6xxl7clRlQ5-nBBpA?token=679679999&lang=zh_CN&poc_token=HKAjo2ejp8XfuHb6tJIRzHRa21sjiyefeGulSxZ5'
            }
          ],
        },
      ],
      contactStatus: false,
      isHover: false,
      companyNewsList,
      associationNewsList,
      positionList,
    }
  },
  created() {
    this.screenHeight = window.innerHeight;
  },
  mounted() {
    this.updateFontSize();
    window.addEventListener('resize', this.updateFontSize);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.updateFontSize);
  },
  methods:{
    updateFontSize() {
      const ratio = window.devicePixelRatio || 1;
      this.fontSize = Math.round(16 * ratio); // 根据像素比例调整字体大小
    },
    handleResize() {
      this.screenHeight = window.innerHeight;
    },
    handRowClick(row){
      window.open(`/position?id=${row.id}`)
    },
    handAssociationNewsRowClick(row){
      window.open(row.link);
    },
    handCompanyNewsClick(row){
      window.open(row.link);
    },
    handleAnchor(){
      const element = document.querySelector('#introduce');
      const rect = element.getBoundingClientRect();
      const offset = 90; // 设置一个适当的偏移量，根据实际情况调整
      window.scrollTo({
        top: rect.top + window.pageYOffset - offset,
        behavior: 'smooth'
      });
    },
    introduceHandle(path) {
      this.$router.push(path).then(() => {
        window.scrollTo(0, 0);
      });
    },
    changeStyle(isHover) {
      this.isHover = isHover;
    },
  }
}
</script>

<style scoped lang="scss">
  /*大背景*/
  .logo_bg{
    position: relative;
  }
  .logo_bg .el-image{
    height: 100%;
    width: 100%;
  }
  .yn_introduce_title {
    color: #B01F24;
    font-size: 52px;
    padding-bottom: 40px;
    font-weight: bold;
  }
  /*元年介绍*/
  .introduce_bg {
    background: url("../assets/introduce_bg.png");
  }
  .yn_introduce {
    padding: 60px 0;
    .left {
      width: 46%;
      .yn_introduce_content {
        color: #252525;
        font-size: 20px;
        line-height: 35px;
        text-align: justify;
      }
      .yn_introduce_tag {
        .item {
          width: 100%;
          cursor: pointer;
          border: 1px solid #21374E;
          margin-top: 50px;
          font-size: 30px;
          height: 112px;
          padding: 0 50px;
          color: #21374E;
          .icon {
            width: 46px;
            height: 46px;
            margin-right: 15px;
          }
        }
        .item:hover {
          background: #21374E;
          color: #fff;
        }
        .item:first-child {
          margin-top: 0;
        }
      }
    }
  }
  // 加入我们
  .join_my {
    padding-top: 40px;
    .yn_join {
      flex-wrap: wrap;
      .join_item{
        width: 22%;
        margin-right: 4%;
        font-size: 20px;
        border: 1px solid #252525;
        padding: 12px 0 12px 15px;
        margin-top: 40px;
        cursor: pointer;
        transition: transform 0.3s;
      }
      .join_item:hover {
        background-color: #21374E;
        color: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        transform: scale(1.1);
      }
      .join_item:nth-child(10) {
        width: 30%;
      }
      .join_item:nth-child(4), .join_item:nth-child(8) {
        margin-right: 0;
      }
    }
  }
  // 新闻资讯
  .new {
    .slide {
      display: flex;
      justify-content: space-between;
      .image-container {
        width: 30%; /* 每个图片容器宽度占比 */
        margin-right: 5%; /* 最后一个图片容器无间距 */
        cursor: pointer;
        .new_img {
          width: 506px;
          height: 216px;
        }
        .new_name {
          font-size: 23px;
          color: #252525;
          margin-top: 15px;
          line-height: 35px;
        }
      }
      .image-container:last-child {
        margin-right: 0; /* 最后一个图片容器无间距 */
      }
      .image-container:hover .new_name{
        color: #B01F24;
        text-decoration: underline;
      }
    }
  }
</style>

<style scoped>
  .about_brief{
    position: absolute;
    right:4%;
    top: 66%;
    width: 554px;
    height: 265px;
    background: #21374e99;
    padding: 36px 84px 36px 70px;
    color: #E5E5E5;
    cursor: pointer;
  }
  h4{
    font-size: 28px;
  }
  .more{
    color: #E5E5E5!important;
  }
  .brief_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }
</style>

