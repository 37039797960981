<template>
  <div>
    <div :class="pageSource === 'rmb' ? 'rmbContact' : 'commonContact'" style="padding: 50px 0 40px; text-align: center; font-size: 24px;">
      <div class="introduce_contact">{{ language === 'chinese' ? '如有疑问或需求，欢迎与我们联系' : 'Please contact us for any enquires and service needs.'}}</div>
      <span :class="['introduce_btn', pageSource === 'rmb' ? 'commonContact' : 'rmbContact']" @click="contactHandle">{{ language === 'chinese' ? '联系我们' : 'Please Contact Us'}}</span>
    </div>
    <!-- 弹窗 -->
    <div class="dialog_connection" v-if="contactStatus">
      <img :src="close" alt="" class="close" @click="closeHandle">
      <img :src="wx4" alt="" class="connection_img">
      <p>{{ language === 'chinese' ? '欢迎扫码联系官方客服' : 'Contact Us'}}</p>
    </div>
  </div>
</template>

<script>
import wx4 from '../assets/wx4.png';
import close from '../assets/close.png';
export default {
  props: {
    contactStatus: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: 'chinese'
    },
    pageSource: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      wx4,
      close,
    }
  },
  methods: {
    contactHandle() {
      this.contactStatus = true;
    },
    closeHandle() {
      this.contactStatus = false;
    },
  }
}
</script>


<style scoped lang="scss">
.introduce_btn {
  display: inline-block;
  background: #fff;
  padding: 12px 40px;
  margin-top: 30px;
  // color: #21374E;
  font-weight: bold;
  cursor: pointer;
}
.introduce_btn:hover {
  color: #B01F24;
}
// 弹窗
.dialog_connection {
  width: 542px;
  height: 446px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  text-align: center;
  .close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .connection_img {
    width: 203px;
    height: 203px;
    margin-top: 15%;
  }
  p {
    font-size: 32px;
    color: #21374E;
    padding-top: 20px;
  }
}
.commonContact {
  background: #21374E;
  color: #fff;
}

.rmbContact {
  background: #fff !important;
  padding-top: 10px !important;
  color: #21374E !important;
}

</style>