<template>
  <div class="rmb">
    <topMenu></topMenu>
    <div class="logo_bg">
      <el-image :src="RMB">
        <div slot="placeholder">图片加载中</div>
      </el-image>
      <div class="main_title">RMB Fund Administration Services</div>
    </div>
    <!-- 大类 -->
    <div class="broad_category flex justify_content">
      <div class="item" v-for="(item, index) in rmbList" :key="index">
        <img :src="item.img" alt="">
        <p>{{item.name}}</p>
      </div>
    </div>
    <Contact :dialog="contactStatus" :language="language" :pageSource="pageSource"></Contact>
    <div class="introduce_bg">
      <!-- 基金设立备案咨询服务 -->
      <div class="manage flex">
        <div class="left">
          <img :src="rmb_7" alt="" style="width: 174px; height: 187px;">
        </div>
        <div class="right">
          <div class="title" style="color: #fff;">Fund Establishment&Filing Consulting Service</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li class="rmb_item2 flex align_items rmb_content"><div class="circle"></div><span>Fund formation consultation</span></li>
              <li class="rmb_item3 flex align_items rmb_content"><div class="circle"></div><span>Review of fund documentations</span></li>
              <li class="rmb_item2 flex align_items rmb_content"><div class="circle"></div><span>Fund registration consultation</span></li>
              <li class="rmb_item3 flex align_items rmb_content"><div class="circle"></div><span>Fund filing assistance</span></li>
              <li class="rmb_item2 flex align_items rmb_content"><div class="circle"></div><span>Fund Raising Supervision Institution & Custodian institution referrals</span></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 基金行政管理服务 -->
      <div class="foundation flex justify_content">
        <div class="left">
          <div class="title">Fund Administration Services</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Fund accounting and Bookkeeping</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Unit Registration and Valuation Accounting</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Fund complex expense&high-water-mark/waterfall</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div>Liaison with auditor</li>
              <li class="rmb_item flex align_items rmb_content"><span style="padding-left: 14px;">and investor allocation calculation</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Investor services</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Fund Financial Reporting</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Fund liquidation</span></li>
            </ul>
          </div>
        </div>
        <div class="right">
          <img :src="rmb_4" alt="" style="width: 200px; height: 200px;">
        </div>
      </div>
      <!-- 监管报表编制及信息披露咨询服务 -->
      <div class="manage flex">
        <div class="left" style="width: 20%;">
          <img :src="rmb_8" alt="" style="width: 210px; height: 210px;">
        </div>
        <div class="right" style="width: 80%;">
          <div class="title">Regulatory statement preparation & Information disclosure</div>
          <div class="content">
            <ul>
              <li class="flex align_items"><div class="circle"></div>AMAC Quarterly Fund Report and Financial Monitoring Fund Report</li>
              <li class="flex align_items"><div class="circle"></div>AMAC Fund manager’s annual audited financial report </li>
              <li class="flex align_items"><div class="circle"></div>AMAC Fund semi-annual & annual reports</li>
              <li class="flex align_items"><div class="circle"></div>Fund Material Amendments Consulting </li>
              <li class="flex align_items"><div class="circle"></div>CRS filing</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 基金管理人咨询服务 -->
      <div class="foundation flex justify_content">
        <div class="left">
          <div class="title">Fund Manager Consulting Service</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Nationwide Investment entities policy consulting</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>PFM&Fund Compliance Advices</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Company registration consultation</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div>Regulatory policy interpretation & training</li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div>PFM Registration Consulting</li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Auditor&Law firm referrals</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>PFM Material Amendments Consulting</span></li>
            </ul>
          </div>
        </div>
        <div class="right">
          <img :src="rmb_6" alt="" style="width: 200px; height: 200px;">
        </div>
      </div>
    </div>
    <div class="footer" id="footer">
      <FooterBottom :language="language"></FooterBottom>
    </div>
  </div>
</template>

<script>
import TopMenu from '../menuEnglish.vue';
import Contact from '../contact';
import FooterBottom from '../footer';
import RMB from '../../assets/rmb_bg.png';
import rmb_1 from '../../assets/rmb_1.png';
import rmb_2 from '../../assets/rmb_2.png';
import rmb_3 from '../../assets/rmb_3.png';
import rmb_4 from '../../assets/rmb_4.png';
import rmb_6 from '../../assets/rmb_6.png';
import rmb_7 from '../../assets/rmb_7.png';
import rmb_8 from '../../assets/rmb_8.png';

export default {
  name: 'RMB',
  components: {TopMenu, Contact, FooterBottom},
  data() {
    return {
      RMB,
      rmb_1,
      rmb_2,
      rmb_3,
      rmb_4,
      rmb_6,
      rmb_7,
      rmb_8,
      rmbList: [
        { img: rmb_1, name: 'Fund Establishment&Filing Consulting Service' },
        { img: rmb_2, name: 'Fund Administration Services' },
        { img: rmb_3, name: 'Regulatory statement preparation & Information disclosure' },
        { img: rmb_6, name: 'Fund Manager Consulting Service' },
      ],
      contactStatus: false,
      language: 'english',
      pageSource: 'rmb',
    }
  }
}  
</script>

<style scoped lang="scss">
  .rmb {
    /*大背景*/
    .logo_bg{
      position: relative;
      padding-top: 90px;
      .main_title {
        position: absolute;
        left: 110px;
        bottom: 25%;
        font-size: 60px;
        font-weight: bold;
        color: #fff;
      }
    }
    .logo_bg .el-image{
      height: 100%;
      width: 100%;
    }
    /*大类 */
    .broad_category {
      padding: 60px 210px 40px 210px;
      .item {
        width: 24%;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        text-align: center;
        img {
          width: 170px;
          height: 170px;
          margin: 30px 0;
        }
        p {
          color: #21374E;
          font-size: 25px;
          font-weight: bold;
          padding: 0 20px 50px;
        }
      }
    }
    .title {
      font-size: 40px;
      font-weight: bold;
      padding-bottom: 30px;
    }
    ul>li {
      font-size: 20px;
      line-height: 40px;
    }
    .introduce_bg {
      background: url("../../assets/introduce_bg1.png") no-repeat;
    }
    .rmb_item {
      width: 40%;
    }
    .rmb_item1 {
      width: 60%;
      padding-left: 25px;
    }
    .rmb_item2 {
      width: 65%;
    }
    .rmb_item3 {
      width: 35%;
      padding-left: 25px;
    }
    .rmb_content {
      line-height: 40px;
      font-size: 20px;
    }
    .rmb_content span {
      width: calc(100% - 14px);
    }
    .circle {
      width: 4px;
      height: 4px;
      background: #fff;
      border-radius: 50%;
      margin-right: 10px;
    }
    /*基金设立备案咨询服务 */
    .manage {
      color: #fff;
      padding: 60px 210px;
      background: url('../../assets/rmb_5.jpg');
      .left {
        width: 30%;
      }
      .right {
        width: 70%;
      }
    }
    /*基金全生命周期服务 */
    .foundation {
      padding: 60px 210px;
      color: #21374E;
      .left {
        width: 80%;
        .rmb_item {
          width: 52%;
        }
        .rmb_item1 {
          width: 48%;
          padding-left: 15px;
        }
        .circle {
          width: 4px;
          height: 4px;
          background: #21374E;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      .right {
        width: 24%;
        text-align: right;
        img {
          margin-top: 30px;
        }
        p {
          font-size: 14px;
          padding-right: 20px;
          line-height: 25px;
        }
      }
    }
  }
  
</style>