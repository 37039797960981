<template>
  <div class="rmb">
    <topMenu></topMenu>
    <div class="logo_bg">
      <el-image :src="dollar_bg">
        <div slot="placeholder">图片加载中</div>
      </el-image>
      <div class="main_title">美元基金行政管理服务</div>
    </div>
    <!-- 大类 -->
    <div class="broad_category">
      <ul>
        <li v-for="(item, index) in categoryList" :key="index">{{item.name}}</li>
      </ul>
      <div class="flex justify_content" style="padding-top: 20px;">
        <div class="item" v-for="(item, index) in rmbList" :key="index">
          <img :src="item.img" alt="">
          <p>{{item.name}}</p>
        </div>
      </div>
    </div>
    <Contact :dialog="contactStatus"></Contact>
    <div class="introduce_bg">
      <!-- 基金运营服务 -->
      <div class="manage flex align_items">
        <div class="left">
          <div class="title">基金运营服务</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>联合合作机构协助基金架构设立</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>基金账务记录，交易维护，投资组合对账</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div>实体设立（开曼、BVI、中国香港等）</li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>基金报表编制及基金报告</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>基金文件审阅</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>基金及投资人分配计算</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>资金核查（Cash monitoring）</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div>基金审计支持</li>
            </ul>
          </div>
        </div>
        <div class="right" style="text-align: right;">
          <img :src="dollar_1" alt="" style="width: 210px; height: 210px;">
        </div>
      </div>
      <!-- 投资者服务* -->
      <div class="foundation flex justify_content align_items">
        <div class="left">
          <img :src="dollar_4" alt="" style="width: 174px; height: 187px;">
        </div>
        <div class="right">
          <div class="title" style="color: #fff;">投资者服务</div>
          <div class="content">
            <ul>
              <li>维护投资者份额登记</li>
              <li>处理投资人申赎，基金缴款/分配通知书</li>
              <li>投资者反洗钱及KYC审核</li>
              <li>投资者报告，发放投资者信函，解答投资者疑问</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 基金反洗钱及合规服务 -->
      <div class="manage flex align_items">
        <div class="left">
          <div class="title">基金反洗钱及合规服务</div>
          <div class="content">
            <ul>
              <li>基金反洗钱活动跟踪（AML on-going monitoring）</li>
              <li>FATCA/CRS申报及根据当地监管要求所需其他合规申报服务</li>
              <li>委任反洗钱合规官并提供反洗钱合规培训 （AML officer(s) mandate）</li>
              <li>起草和提交可疑活动报告 （SAR）</li>
              <li>海外基金法规跟踪</li>
            </ul>
          </div>
        </div>
        <div class="right" style="text-align: right;">
          <img :src="dollar_3" alt="" style="width: 210px; height: 210px;">
        </div>
      </div>
    </div>  
    <div class="footer" id="footer">
      <FooterBottom></FooterBottom>
    </div>
  </div>
</template>

<script>
import TopMenu from '../menu.vue';
import Contact from '../contact';
import FooterBottom from '../footer';
import dollar_bg from '../../assets/dollar_bg.png';
import dollar_1 from '../../assets/dollar_1.png';
import dollar_2 from '../../assets/dollar_2.png';
import dollar_3 from '../../assets/dollar_3.png';
import dollar_4 from '../../assets/dollar_4.png';

export default {
  name: 'RMB',
  components: {TopMenu, Contact, FooterBottom},
  data() {
    return {
      dollar_bg,
      dollar_1,
      dollar_2,
      dollar_3,
      dollar_4,
      rmbList: [
        { img: dollar_1, name: '基金运营服务' },
        { img: dollar_2, name: '投资者服务' },
        { img: dollar_3, name: '基金反洗钱及合规服务' },
      ],
      categoryList: [
        {name: '服务基金： 开放式、封闭式、FOF、私募债、私募股权等'},
        {name: '海外基金设立地： 开曼、BVI、中国香港、新加坡等'},
      ],
      contactStatus: false
    }
  }
}  
</script>

<style scoped lang="scss">
  .rmb {
    /*大背景*/
    .logo_bg{
      position: relative;
      padding-top: 90px;
      .main_title {
        position: absolute;
        left: 110px;
        bottom: 25%;
        font-size: 60px;
        font-weight: bold;
        color: #fff;
      }
    }
    .logo_bg .el-image{
      height: 100%;
      width: 100%;
    }
    /*大类 */
    .broad_category {
      padding: 30px 210px 40px 210px;
      .item {
        width: 31.5%;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        text-align: center;
        img {
          width: 170px;
          height: 170px;
          margin: 20px 0 0px;
        }
        p {
          color: #21374E;
          font-size: 40px;
          font-weight: bold;
          padding-bottom: 50px;
        }
      }
    }
    .title {
      font-size: 40px;
      font-weight: bold;
      color: #21374E;
      padding-bottom: 30px;
    }
    ul {
      padding-left: 25px;
    }
    ul>li {
      font-size: 20px;
      line-height: 40px;
    }
    .introduce_bg {
      background: url("../../assets/introduce_bg.png");
    }
    /*基金运营服务 */
    .manage {
      padding: 40px 210px;
      .left {
        width: 70%;
        .rmb_item {
          width: 60%;
        }
        .rmb_item1 {
          width: 40%;
        }
        .rmb_content {
          line-height: 40px;
          font-size: 20px;
        }
        .rmb_content span {
          width: calc(100% - 14px);
        }
        .circle {
          width: 4px;
          height: 4px;
          background: #000;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      .right {
        width: 30%;
      }
    }
    /*基金全生命周期服务 */
    .foundation {
      padding: 30px 210px;
      height: 312px;
      background: url("../../assets/dollar_5.png");
      color: #fff;

      .left {
        width: 45%;
      }
      .right {
        width: 55%;
        img {
          margin-top: 30px;
        }
        p {
          font-size: 18px;
          padding-right: 20px;
          padding-top: 40px;
        }
      }
    }
  }
  
</style>