<template>
  <div class="rmb">
    <topMenu></topMenu>
    <div class="logo_bg">
      <el-image :src="RMB">
        <div slot="placeholder">图片加载中</div>
      </el-image>
      <div class="main_title">人民币基金行政管理服务</div>
    </div>
    <!-- 大类 -->
    <div class="broad_category flex justify_content">
      <div class="item" v-for="(item, index) in rmbList" :key="index">
        <img :src="item.img" alt="">
        <p>{{item.name}}</p>
      </div>
    </div>
    <Contact :dialog="contactStatus" :pageSource="pageSource"></Contact>
    <div class="introduce_bg">
      <!-- 基金设立备案咨询服务 -->
      <div class="manage flex">
        <div class="left">
          <img :src="rmb_7" alt="" style="width: 174px; height: 187px; margin-top: 50px;">
        </div>
        <div class="right">
          <div class="title">基金设立备案咨询服务</div>
          <div class="content">
            <ul>
              <li>基金架构咨询建议</li>
              <li>基金主体注册咨询</li>
              <li>基金募集监督机构及托管机构推介</li>
              <li>从运营角度协助审阅基金备案文件</li>
              <li>基金备案协助</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 基金行政管理服务 -->
      <div class="foundation flex justify_content">
        <div class="left">
          <div class="title">基金行政管理服务</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li>基金会计核算及账务处理</li>
              <li>基金审计支持</li>
              <li>基金复杂费用及分配计算</li>
              <li>基金投资人服务</li>
              <li>基金财务报表及报告编制</li>
              <li>基金清算服务</li>
              <li>基金份额登记&估值核算</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <img :src="rmb_4" alt="" style="width: 200px; height: 200px;">
        </div>
      </div>
      <!-- 监管报表编制及信息披露咨询服务 -->
      <div class="manage flex">
        <div class="left">
          <img :src="rmb_8" alt="" style="width: 210px; height: 210px; margin-top: 40px;">
        </div>
        <div class="right">
          <div class="title">监管报表编制及信息披露咨询服务</div>
          <div class="content">
            <ul>
              <li>Ambers系统基金季度更新、财务监测报告编制</li>
              <li>Ambers系统管理人年度经审计的财务报告更新</li>
              <li>信披系统基金半年报及年报编制</li>
              <li>基金重大事项变更咨询</li>
              <li>CRS申报咨询</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 基金管理人咨询服务 -->
      <div class="foundation flex justify_content">
        <div class="left">
          <div class="title">基金管理人咨询服务</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li>全国基金小镇政策咨询</li>
              <li>管理人及基金合规咨询</li>
              <li>管理人主体注册咨询</li>
              <li>监管政策解读及培训</li>
              <li>管理人登记咨询</li>
              <li>审计机构、律所等机构推介</li>
              <li>管理人重大事项变更咨询</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <img :src="rmb_6" alt="" style="width: 200px; height: 200px;">
        </div>
      </div>
    </div>
    <div class="footer" id="footer">
      <FooterBottom></FooterBottom>
    </div>
  </div>
</template>

<script>
import TopMenu from '../menu.vue';
import Contact from '../contact';
import FooterBottom from '../footer';
import RMB from '../../assets/rmb_bg.png';
import rmb_1 from '../../assets/rmb_1.png';
import rmb_2 from '../../assets/rmb_2.png';
import rmb_3 from '../../assets/rmb_3.png';
import rmb_4 from '../../assets/rmb_4.png';
import rmb_6 from '../../assets/rmb_6.png';
import rmb_7 from '../../assets/rmb_7.png';
import rmb_8 from '../../assets/rmb_8.png';

export default {
  name: 'RMB',
  components: {TopMenu, Contact, FooterBottom},
  data() {
    return {
      RMB,
      rmb_1,
      rmb_2,
      rmb_3,
      rmb_4,
      rmb_6,
      rmb_7,
      rmb_8,
      rmbList: [
        { img: rmb_1, name: '基金设立备案咨询服务' },
        { img: rmb_2, name: '基金行政管理服务' },
        { img: rmb_3, name: '监管报表编制及信息披露咨询服务' },
        { img: rmb_6, name: '基金管理人咨询服务' },
      ],
      contactStatus: false,
      pageSource: 'rmb'
    }
  }
}  
</script>

<style scoped lang="scss">
  .rmb {
    /*大背景*/
    .logo_bg{
      position: relative;
      padding-top: 90px;
      .main_title {
        position: absolute;
        left: 110px;
        bottom: 25%;
        font-size: 60px;
        font-weight: bold;
        color: #fff;
      }
    }
    .logo_bg .el-image{
      height: 100%;
      width: 100%;
    }
    /*大类 */
    .broad_category {
      padding: 60px 210px 40px 210px;
      .item {
        width: 24%;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        text-align: center;
        img {
          width: 170px;
          height: 170px;
          margin: 30px 0;
        }
        p {
          color: #21374E;
          font-size: 30px;
          font-weight: bold;
          padding: 0 16px 50px;
        }
      }
    }
    .title {
      font-size: 40px;
      font-weight: bold;
      color: #21374E;
      padding-bottom: 30px;
    }
    ul {
      padding-left: 25px;
    }
    ul>li {
      font-size: 20px;
      line-height: 40px;
    }
    .introduce_bg {
      background: url("../../assets/introduce_bg.png");
    }
    /*基金设立备案咨询服务 */
    .manage {
      color: #fff;
      padding: 40px 210px;
      background: url('../../assets/rmb_5.jpg');
      .title {
        color: #fff;
      }
      .left {
        width: 45%;
      }
      .right {
        width: 55%;
        ul>li:nth-child(1), ul>li:nth-child(3), ul>li:nth-child(5){
          width: 62%;
        }
      }
    }
    /*基金全生命周期服务 */
    .foundation {
      padding: 55px 210px 30px;
      height: 354px;
      color: #21374E;
      .left {
        width: 75%;
        ul>li:nth-child(1), ul>li:nth-child(3), ul>li:nth-child(5), ul>li:nth-child(7), ul>li:nth-child(9){
          width: 40%;
        }
        ul>li:nth-child(2), ul>li:nth-child(4), ul>li:nth-child(6), ul>li:nth-child(8), ul>li:nth-child(10){
          width: 60%;
        }
      }
      .right {
        width: 25%;
        text-align: right;
        img {
          margin-top: 30px;
        }
        p {
          font-size: 18px;
          padding-right: 20px;
          padding-top: 40px;
        }
      }
    }
  }
  
</style>