<template>
  <div class="rmb">
    <topMenu></topMenu>
    <div class="logo_bg">
      <el-image :src="manage_bg">
        <div slot="placeholder">图片加载中</div>
      </el-image>
      <div class="main_title">资管机构中后台运营全方位专业支持</div>
    </div>
    <!-- 大类 -->
    <div class="broad_category flex justify_content">
      <div class="item" v-for="(item, index) in rmbList" :key="index">
        <img :src="item.img" alt="">
        <p>{{item.name}}</p>
      </div>
    </div>
    <Contact :dialog="contactStatus"></Contact>
    <div class="introduce_bg">
      <!-- 人资外包服务 -->
      <div class="manage flex align_items">
        <div class="left">
          <img :src="manage_1" alt="" style="width: 210px; height: 210px;">
        </div>
        <div class="right">
          <div class="title" style="padding-left: 25px;">驻场运营服务 </div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>估值核算</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>数据报送</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>产品支持</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>份额登记</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>数据运营与分析</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>交易支持</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>清算经办</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>信息披露</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>客户服务</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>账户管理</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>稽核/合规</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>系统维护</span></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 资管运营培训 -->
      <div class="foundation flex justify_content align_items">
        <div class="left">
          <div class="title" style="color: #fff;">资管运营培训</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li>上岗培训</li>
              <li>监管政策解读</li>
              <li>业务知识迭代培训</li>
              <li>软技能培训</li>
            </ul>
          </div>
        </div>
        <div class="right" style="text-align: right;">
          <img :src="manage_4" alt="" style="width: 200px; height: 200px;">
        </div>
      </div>
      <!-- 资管运营咨询  -->
      <div class="manage flex align_items">
        <div class="left">
          <img :src="manage_3" alt="" style="width: 210px; height: 210px;">
        </div>
        <div class="right" style="padding-left: 25px;">
          <div class="title">资管运营咨询</div>
          <div class="content">
            <ul>
              <li>运营流程梳理优化</li>
              <li>复杂产品运营实操</li>
            </ul>
          </div>
        </div>
      </div>
    </div>  
    <div class="footer" id="footer">
      <FooterBottom></FooterBottom>
    </div>
  </div>
</template>

<script>
import TopMenu from '../menu.vue';
import Contact from '../contact';
import FooterBottom from '../footer';
import manage_bg from '../../assets/manage_bg.png';
import manage_1 from '../../assets/manage_1.png';
import manage_2 from '../../assets/manage_2.png';
import manage_3 from '../../assets/manage_3.png';
import manage_4 from '../../assets/manage_4.png';

export default {
  name: 'RMB',
  components: {TopMenu, Contact, FooterBottom},
  data() {
    return {
      manage_bg,
      manage_1,
      manage_2,
      manage_3,
      manage_4,
      rmbList: [
        { img: manage_1, name: '驻场运营服务' },
        { img: manage_2, name: '资管运营培训' },
        { img: manage_3, name: '资管运营咨询' },
      ],
      contactStatus: false,
    }
  }
}  
</script>

<style scoped lang="scss">
  .rmb {
    /*大背景*/
    .logo_bg{
      position: relative;
      padding-top: 90px;
      .main_title {
        position: absolute;
        left: 110px;
        bottom: 25%;
        font-size: 60px;
        font-weight: bold;
        color: #fff;
      }
    }
    .logo_bg .el-image{
      height: 100%;
      width: 100%;
    }
    /*大类 */
    .broad_category {
      padding: 60px 210px 40px 210px;
      .item {
        width: 31.5%;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        text-align: center;
        img {
          width: 170px;
          height: 170px;
          margin: 40px 0 20px;
        }
        p {
          color: #21374E;
          font-size: 40px;
          font-weight: bold;
          padding-bottom: 60px;
        }
      }
    }
    .title {
      font-size: 40px;
      font-weight: bold;
      color: #21374E;
      padding-bottom: 30px;
    }
    ul {
      padding-left: 25px;
    }
    ul>li {
      font-size: 20px;
      line-height: 40px;
    }
    .introduce_bg {
      background: url("../../assets/introduce_bg.png");
    }
    /*基金运营服务 */
    .manage {
      padding: 60px 210px;
      .left {
        width: 40%;
      }
      .right {
        width: 60%;
        .rmb_item {
          width: 33.33%;
        }
        .rmb_content {
          line-height: 40px;
          font-size: 20px;
        }
        .rmb_content span {
          width: calc(100% - 14px);
        }
        .circle {
          width: 4px;
          height: 4px;
          background: #000;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      
    }
    /*基金全生命周期服务 */
    .foundation {
      padding: 30px 210px;
      // background: #21374E;
      height: 288px;
      background: url("../../assets/manage_5.png");
      color: #fff;

      .left {
        width: 45%;
        .content {
          ul>li {
            width: 50%;
          }
        }
      }
      .right {
        width: 55%;
        img {
          margin-top: 30px;
        }
        p {
          font-size: 18px;
          padding-right: 20px;
          padding-top: 40px;
        }
      }
    }
    .tip {
      background: #21374E;
      color: #fff;
      text-align: center;
      font-size: 20px;
      padding: 80px 0 40px;
      line-height: 35px;
    }
  }
  
</style>