<template>
  <div class="rmb">
    <topMenu></topMenu>
    <div class="logo_bg">
      <el-image :src="dollar_bg">
        <div slot="placeholder">图片加载中</div>
      </el-image>
      <div class="main_title">USD Fund Administration Services</div>
    </div>
    <!-- 大类 -->
    <div class="broad_category">
      <ul>
        <li v-for="(item, index) in categoryList" :key="index"><span style="font-weight: bold;">{{item.title}}</span>{{item.name}}</li>
      </ul>
      <div class="flex justify_content" style="padding-top: 20px;">
        <div class="item" v-for="(item, index) in rmbList" :key="index">
          <img :src="item.img" alt="">
          <p>{{item.name}}</p>
        </div>
      </div>
    </div>
    <Contact :dialog="contactStatus" :language="language"></Contact>
    <div class="introduce_bg">
      <!-- Fund Administration Services -->
      <div class="manage flex align_items">
        <div class="left" style="width: 100%;">
          <div class="title">Fund Administration Services</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap; padding-left: 0px;">
              <li class="rmb_item flex align_items rmb_content" style="width: 51%;"><div class="circle"></div><span>Liaison with partners in fund formation</span></li>
              <li class="rmb_item1 flex align_items rmb_content" style="width: 49%;"><div class="circle"></div><span>Bookkeeping, transaction maintenance, and portfolio reconciliation</span></li>
              <li class="rmb_item flex align_items rmb_content" style="width: 51%;"><div class="circle"></div><span>Incorporation of offshore entities (Cayman Islands, BVI，Hong Kong etc.)</span></li>
              <li class="rmb_item1 flex align_items rmb_content" style="width: 49%;"><div class="circle"></div><span>Fund NAV calculation and fund financial reporting</span></li>
              <li class="rmb_item flex align_items rmb_content" style="width: 51%;"><div class="circle"></div><span>Review of fund documentations</span></li>
              <li class="rmb_item1 flex align_items rmb_content" style="width: 49%;"><div class="circle"></div><span>Fund expense, high-water-mark/waterfall, and investor allocation calculations</span></li>
              <li class="rmb_item flex align_items rmb_content" style="width: 51%;"><div class="circle"></div><span>Cash monitoring</span></li>
              <li class="rmb_item1 flex align_items rmb_content" style="width: 49%;"><div class="circle"></div><span>Liaison with auditor</span></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Investor Services* -->
      <div class="foundation flex justify_content align_items">
        <div class="left" style="width: 35%;">
          <img :src="dollar_4" alt="" style="width: 174px; height: 187px;">
        </div>
        <div class="right" style="width: 65%;">
          <div class="title" style="color: #fff;">Investor Services</div>
          <div class="content">
            <ul>
              <li>Share registry maintenance</li>
              <li>Review on investor subscription/redemption confirmation, issuance of capital call/distribution notice</li>
              <li>Investor AML KYC review</li>
              <li>Preparation of investor capital account statements and investor relations maintenance</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Fund Anti-Money Laundering and Compliance Services -->
      <div class="manage flex align_items">
        <div class="left">
          <div class="title">Fund Anti-Money Laundering And Compliance Services</div>
          <div class="content">
            <ul>
              <li>AML on-going monitoring</li>
              <li>FATCA CRS filing </li>
              <li>AML officers mandate and annual AML training</li>
              <li>Drafting and filing SAR reports</li>
              <li>AML regulation tracking</li>
            </ul>
          </div>
        </div>
        <div class="right" style="text-align: right;">
          <img :src="dollar_3" alt="" style="width: 210px; height: 210px;">
        </div>
      </div>
    </div>  
    <div class="footer" id="footer">
      <FooterBottom :language="language"></FooterBottom>
    </div>
  </div>
</template>

<script>
import TopMenu from '../menuEnglish.vue';
import Contact from '../contact';
import FooterBottom from '../footer';
import dollar_bg from '../../assets/dollar_bg.png';
import dollar_1 from '../../assets/dollar_1.png';
import dollar_2 from '../../assets/dollar_2.png';
import dollar_3 from '../../assets/dollar_3.png';
import dollar_4 from '../../assets/dollar_4.png';

export default {
  name: 'RMB',
  components: {TopMenu, Contact, FooterBottom},
  data() {
    return {
      dollar_bg,
      dollar_1,
      dollar_2,
      dollar_3,
      dollar_4,
      rmbList: [
        { img: dollar_1, name: 'Fund Administration Services' },
        { img: dollar_2, name: 'Investor Services' },
        { img: dollar_3, name: 'Fund Anti-Money Laundering And Compliance Services' },
      ],
      categoryList: [
        {title: 'Funds to serve：', name: 'Open-ended funds, Closed-ended funds, FoFs, Private credit funds, Private equity funds, etc.'},
        {title: 'Jurisdictions to cover：', name: 'Cayman Islands, British Virgin Islands, Hong Kong, Singapore, etc.'},
      ],
      contactStatus: false,
      language: 'english',
    }
  }
}  
</script>

<style scoped lang="scss">
  .rmb {
    /*大背景*/
    .logo_bg{
      position: relative;
      padding-top: 90px;
      .main_title {
        position: absolute;
        left: 110px;
        bottom: 25%;
        font-size: 60px;
        font-weight: bold;
        color: #fff;
      }
    }
    .logo_bg .el-image{
      height: 100%;
      width: 100%;
    }
    /*大类 */
    .broad_category {
      padding: 30px 210px 40px 210px;
      .item {
        width: 31.5%;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        text-align: center;
        img {
          width: 170px;
          height: 170px;
          margin: 20px 0 0px;
        }
        p {
          color: #21374E;
          font-size: 29px;
          font-weight: bold;
          padding: 0 20px 50px;
        }
      }
    }
    .title {
      font-size: 40px;
      font-weight: bold;
      color: #21374E;
      padding-bottom: 30px;
    }
    ul {
      padding-left: 25px;
    }
    ul>li {
      font-size: 20px;
      line-height: 40px;
    }
    .introduce_bg {
      background: url("../../assets/introduce_bg.png");
    }
    /*Fund Administration Services */
    .manage {
      padding: 40px 210px;
      .left {
        width: 80%;
        .rmb_item {
          width: 60%;
        }
        .rmb_item1 {
          width: 40%;
        }
        .rmb_content {
          line-height: 40px;
          font-size: 20px;
        }
        .rmb_content span {
          width: calc(100% - 14px);
        }
        .circle {
          width: 4px;
          height: 4px;
          background: #000;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      .right {
        width: 20%;
      }
    }
    /*基金全生命周期服务 */
    .foundation {
      padding: 30px 210px;
      height: 312px;
      background: url("../../assets/dollar_5.png");
      color: #fff;

      .left {
        width: 45%;
      }
      .right {
        width: 55%;
        img {
          margin-top: 30px;
        }
        p {
          font-size: 18px;
          padding-right: 20px;
          padding-top: 40px;
        }
      }
    }
  }
  
</style>